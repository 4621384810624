body {
	height: auto;
}

.main {
	margin-top: 4em;
}

/* .masthead.segment {
	/* min-height: 400px;
	padding: 3em 0em;
	margin-top: -3em !important;
} */
/* .masthead .logo.item img {
	margin-right: 1em;
}
.masthead .ui.menu .ui.button {
	margin-left: 0.5em;
}
.masthead h1.ui.header {
	margin-top: 3em;
	margin-bottom: 0em;
	font-size: 3em;
	font-weight: normal;
}
.masthead h2 {
	font-size: 1.2em;
	font-weight: normal;
} */

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: auto;
}

@keyframes App-logo-spin {
	from { transform: rotate(100deg); }
	to { transform: rotate(360deg); }
}


/* The alert message box */
.alert {
	padding: 20px;
	background-color: #4320f5; /* Red */
	color: white;
	margin-bottom: 15px;
  }
  
  /* The close button */
  .closebtn {
	margin-left: 15px;
	color: white;
	font-weight: bold;
	float: right;
	font-size: 25px;
	line-height: 5px;
	cursor: pointer;
	transition: 0.3s;
  }
  
  /* When moving the mouse over the close button */
  .closebtn:hover {
	color: red;
  }

  .close {
  float: right;
  font-size: 25px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .5;
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: .5;
}

.success {
	background-color: #ddffdd;
	border-left: 6px solid #04AA6D;
	margin-bottom: 15px;
	padding: 4px 12px;
  }

  textarea.ui.input[disabled] {
	color: black;
	opacity: 1;
  }

  .disabled {
	pointer-events: none;
	cursor: default;
  }
  
/* 
  .responsive-table {
	overflow-x: auto;
  }
  
  @media (max-width: 767px) {
	.responsive-table table {
	  display: flex;
	  flex-direction: column;
	}
  
	.responsive-table thead,
	.responsive-table tbody {
	  display: flex;
	  flex-wrap: wrap;
	  align-items: center;
	  justify-content: space-between;
	}
  
	.responsive-table tr {
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  justify-content: space-between;
	  margin-bottom: 1em;
	}
  
	.responsive-table th,
	.responsive-table td {
	  display: flex;
	  align-items: center;
	  justify-content: center;
	}
  }
  

  @media screen and (max-width: 767px) {
	.vessa-header {
	  display: inline-block;
	  width: 75%;
	  text-align: left;
	}
  
	.arvostelut-header {
	  display: inline-block;
	  width: 25%;
	  text-align: right;
	}
  } */